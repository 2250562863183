/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-star-rating {
  display: flex;
  align-items: center; }
  .cs-star-rating__stars {
    display: inline-flex;
    padding: 0;
    width: 7.2rem;
    justify-content: space-between; }
    .cs-star-rating--summary .cs-star-rating__stars {
      width: 10rem;
      padding: 0; }
    .cs-star-rating--review .cs-star-rating__stars {
      width: 7.2rem;
      padding: 0; }
  .cs-star-rating__star {
    display: flex;
    width: 1.5rem;
    height: 100%;
    fill: #888; }
    .cs-star-rating__star--active {
      fill: #fcb739; }
    .cs-star-rating__star--half-active .star-left {
      fill: #fcb739; }
    .cs-star-rating__star--half-active .star-rigth {
      fill: #888; }
    .cs-star-rating--summary .cs-star-rating__star {
      width: 2rem; }
      .ie11 .cs-star-rating--summary .cs-star-rating__star {
        height: calc( 2rem / 1.05); }
    .cs-star-rating--review .cs-star-rating__star {
      width: 1.5rem; }
      .ie11 .cs-star-rating--review .cs-star-rating__star {
        height: calc( 1.5rem / 1.05); }
    .cs-star-rating__star path {
      fill-opacity: 1;
      fill: inherit; }
    .ie11 .cs-star-rating__star {
      height: calc( 1.5rem / 1.05); }
  .cs-star-rating img {
    width: 1.5rem;
    height: 100%; }
  .cs-star-rating__form-stars {
    display: inline-flex;
    padding: 0; }
  .cs-star-rating__form-star {
    opacity: 1;
    fill: #888;
    width: 100%; }
    .cs-star-rating__form-star--active {
      fill: #fcb739; }
    .cs-star-rating__form-star path {
      fill-opacity: 1;
      fill: inherit; }
    .cs-star-rating__form-star:nth-last-of-type() {
      padding-right: 0; }
    .cs-star-rating__form-stars:hover .cs-star-rating__form-star {
      fill: #fcb739; }
    .cs-star-rating__form-stars label:hover ~ label .cs-star-rating__form-star {
      fill: #888; }
    .ie11 .cs-star-rating__form-star {
      height: calc( 2rem / 1.05); }
  .cs-star-rating__text {
    display: inline-block;
    color: #696969;
    padding-left: 0.5em;
    line-height: 1;
    padding-top: calc(1.5rem * 0.1); }
    .cs-star-rating__text--count:before {
      content: '('; }
    .cs-star-rating__text--count:after {
      content: ')'; }
  .cs-star-rating__text-annotation {
    display: inline;
    line-height: 1; }
    .cs-product-tile .cs-star-rating__text-annotation {
      display: none; }
  .cs-star-rating__label {
    color: #31261D;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: calc(1.5rem * 0.2); }

.review-control-vote .rating-1:before {
  content: none; }

.review-control-vote .rating-2:before {
  content: none; }

.review-control-vote .rating-3:before {
  content: none; }

.review-control-vote .rating-4:before {
  content: none; }

.review-control-vote .rating-5:before {
  content: none; }

.review-control-vote:before {
  content: none; }

.review-control-vote label {
  position: static;
  width: 3rem;
  padding-right: 1rem;
  line-height: 0; }
  @media (min-width: 48em) {
    .review-control-vote label {
      width: 2.6rem;
      padding-right: 0.6rem; } }
  .review-control-vote label:last-child {
    width: 2rem;
    padding-right: 0; }
