@import 'config/variables';
@import 'components/typography/mixin';
@import 'vendors/include-media';

@import '../../../../theme-creativeshop/src/components/page-title/page-title.scss';

.page-title {
    font-size: 2.5rem;

    @include media('<tablet') {
        margin: 0;
        padding-bottom: 0;
    }

}
