/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-search-results-switcher {
  display: none; }
  .cs-search-results-switcher__triggers {
    overflow: visible !important; }
  .cs-search-results-switcher__content {
    padding: 2rem 0; }
    .cs-search-results-switcher__content:not(.cs-search-results-switcher__content--active) {
      display: none; }
    .cs-search-results-switcher__content--active + .cs-search-results-switcher__content--active {
      border-top: 1px solid #dcdcdc; }
  .cs-search-results-switcher__messages:not(.cs-search-results-switcher__messages--visible) {
    display: none; }
  .cs-search-results-switcher__headline {
    font-family: "Libre Franklin", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Oxygen, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.8rem;
    color: #31261D;
    font-weight: 700;
    text-transform: uppercase;
    margin: 1rem 0 2rem; }
  .cs-search-results-switcher__count:not(:empty), .cs-search-results-switcher__overall-count:not(:empty) {
    font-weight: 300;
    color: #b0b0b0; }
    .cs-search-results-switcher__count:not(:empty):before, .cs-search-results-switcher__overall-count:not(:empty):before {
      content: '('; }
    .cs-search-results-switcher__count:not(:empty):after, .cs-search-results-switcher__overall-count:not(:empty):after {
      content: ')'; }
