@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';

$social-media-list_link-transform: 0deg;
$transform_skew-400-revert: skew(0deg);
$social-media-list_link-border-radius: 50%;
$social-media-list_link-min-width: 3.5em;
$social-media-list_link-background: $color_cold-turkey;
$social-media-list_link-hover-background: $color_shadow;
$social-media-list_icon-color: $color_zeus;

@import '../../../../theme-creativeshop/src/components/social-media-list/social-media-list.scss';

.#{$ns}social-media-list {
    $root: &;

    &__icon {
        #{$root}__item--fb & {
            transform: translate3d(-75%, -50%, 0);
        }
        #{$root}__item--insta & {
            width: 2.1em;
            height: 2.1em;
            transform: translate3d(-57%, -50%, 0);
        }
        #{$root}__item--pint & {
            width: 1.56em;
            height: 2.1em;
            transform: translate3d(-57%, -50%, 0);
        }
    }
}
