@import 'config/variables';

$authorization-link_color: $color_white;
$authorization-link_color-hover: $color_white;

@import '../../../../theme-creativeshop/src/components/authorization-link/authorization-link.scss';

.#{$ns}authorization-link {
    &__link {
        @include link(
            $color: $authorization-link_color,
            $color-hover: $authorization-link_color-hover
        );
    }
}
