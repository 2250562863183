@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/link';

$page-bottom_text-color: $color_wafer;

@import '../../../../theme-creativeshop/src/components/page-bottom/page-bottom.scss';

.#{$ns}page-bottom {
    $root: &;
    padding: 0;

    .copyright{
        color: $color_wafer;
        font-size: $font-size_12;
        border-top: 1px solid $color_wafer;
        width: 100%;
        padding: 1.5rem 0;

        a{
            @include link($color_wafer, $color_wafer);

            &:hover{
                text-decoration: underline;
            }

            @include media('<tablet') {
                font-weight: bold;
                @include link($color_wafer, $color_wafer);
            }
        }

        p{
            display: none;
        }

        .block-static-block.widget, .block-cms-link.widget{
            margin-bottom: 0;

            @include media('<tablet') {
                display: none;
            }
        }

        @include media('<tablet') {
            font-size: $font-size_10;
            padding-top: 1rem;
            padding-bottom: 1.2rem;
            color: $color_wafer;
            border-top: 0;
        }
    }
}

.#{$mg}content{
    &-left{
        float: left;
        padding-top: 0.6rem;
        padding-right: 2rem;

        @include media('<laptop'){
            float: none;
            text-align: center;
        }

        @include media('<tablet') {
            span{
                padding: 0 2rem 0.9rem;
                border-bottom: 1px solid $color_wafer;
            }
        }
    }
    &-right{
        float: right;
        display: flex;
        align-items: center;

        div{
            margin-left: 5rem;
        }

        @include media('<1400px') {
            width: 100%;
            justify-content: flex-end;
            margin-top: 2rem;
        }

        @include media('<laptop'){
            float: none;
            flex-direction: column;

            div{
                margin: 0 2.5rem;
                order: 1;
            }
        }

        @include media('<tablet') {
            text-align: center;
        }

        .footer-payment-methods{
            max-height: 3rem;
            @include media('<laptop'){
                order: 2;
                margin-top: 2rem;
            }
        }
    }
}

.cs-container {
    &--page-bottom {
        background-color: $color_zeus;
    }
}
