@import 'config/variables';
@import 'utils/visually-hidden';
@import 'vendors/include-media';
@import 'etc/view.scss';
@import 'utils/deep-get/deep-get.scss';
@import 'components/button/mixin';

$swatches_swatch-height: 2.2em;
$swatches_swatch-width: 2.2em;

@import '../../../../theme-creativeshop/src/components/swatches/swatches.scss';

.swatch-option-tooltip {
    @include media('<tablet') {
        display: none;
        visibility:hidden;
    }
}

