/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-navigation-teaser__figure {
  display: block;
  max-height: 100%;
  overflow: hidden; }

.cs-navigation-teaser__image {
  display: block; }

.cs-navigation-teaser__content-wrapper {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 6rem 3rem; }

.cs-navigation-teaser__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #212121;
  text-align: center; }

.cs-navigation-teaser__headline {
  margin: 0 0 1rem;
  font-size: 3rem;
  line-height: 1.15;
  font-weight: 700;
  color: #212121; }
  @media (min-width: 64em) {
    .cs-navigation-teaser__headline {
      font-size: 4.3rem;
      line-height: 1; } }

.cs-navigation-teaser__subheadline {
  margin: 0 0 1rem;
  font-size: 1.65rem;
  line-height: 1.15;
  font-weight: 400;
  color: #212121; }
  @media (min-width: 64em) {
    .cs-navigation-teaser__subheadline {
      font-size: 2.8rem;
      line-height: 1.25; } }

.cs-navigation-teaser__text {
  margin: 0 0 1rem;
  font-size: 1rem;
  line-height: 1.15;
  font-weight: 300;
  color: #212121; }
  @media (min-width: 64em) {
    .cs-navigation-teaser__text {
      font-size: 1.4rem; } }

.cs-navigation-teaser__cta {
  margin-top: 2rem; }

.cs-navigation-teaser__button {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.275em;
  margin: 0;
  text-transform: none;
  padding: 0.75em 1em;
  box-sizing: border-box;
  transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
  vertical-align: middle;
  z-index: 5;
  will-change: transform, background-color, border-color, fill, color; }
  .cs-navigation-teaser__button:hover, .cs-navigation-teaser__button:focus, .cs-navigation-teaser__button:active {
    background-color: #31261D !important; }
  .cs-navigation-teaser__button:before, .cs-navigation-teaser__button:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px; }
  .cs-navigation-teaser__button:before {
    width: 3.8em;
    border-radius: 0 3px 3px 0;
    z-index: -1;
    background: linear-gradient(100deg, transparent 0%, transparent 0%, #31261D 0%, #31261D 0%); }
  .cs-navigation-teaser__button:after {
    width: 3em;
    min-width: auto;
    background-color: #31261D;
    transition: width 0.25s linear;
    z-index: -2;
    border-radius: 3px; }
  .cs-navigation-teaser__button:hover:after {
    width: calc(100% + 2px); }
  .cs-navigation-teaser__button::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0; }
  .cs-navigation-teaser__button, .cs-navigation-teaser__button:visited {
    cursor: pointer;
    border-radius: 3px;
    outline-offset: 1px;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #31261D; }
    .cs-navigation-teaser__button *, .cs-navigation-teaser__button:visited * {
      color: #fff;
      fill: #fff; }
  .cs-navigation-teaser__button:hover:not([disabled]), .cs-navigation-teaser__button:focus:not([disabled]), .cs-navigation-teaser__button:active:not([disabled]) {
    box-shadow: none;
    outline: none;
    text-decoration: none;
    border: 1px solid transparent;
    color: #fff;
    background-color: #31261D; }
    .cs-navigation-teaser__button:hover:not([disabled]) *, .cs-navigation-teaser__button:focus:not([disabled]) *, .cs-navigation-teaser__button:active:not([disabled]) * {
      color: #fff;
      fill: #fff; }
  .cs-navigation-teaser__button[disabled] {
    opacity: 0.65;
    cursor: auto; }

.cs-navigation-teaser__button-span {
  margin-top: 0;
  z-index: 1;
  font-weight: 700;
  vertical-align: baseline;
  display: inline-block;
  line-height: 1.275em;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 4em; }

.cs-navigation-teaser__button-icon {
  max-width: 1em;
  max-height: 1.4rem;
  z-index: 2;
  height: auto;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  margin-right: 0;
  right: 1em; }
