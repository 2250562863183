@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/visually-hidden';
@import 'components/qty-counter/mixin';

@import '../../../../../theme-creativeshop/src/components/header/user-nav/user-nav.scss';

.#{$ns}header-user-nav {
    $root: &;

    &__qty-counter {
        &--minicart {
            background-color: $color_zeus;
        }
    }
    &__icon {
        width: $header-user-nav_icon-width;
        height: $header-user-nav_icon-height;
        @media screen and (max-width: 767px) {
            width: 1.8rem;
            height: 1.8rem;
        }
        display: block;
        margin: 0 auto;

        path {
            fill: $header-user-nav_icon-color;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding: 0.8rem;

        &:hover {
            text-decoration: none;

            #{$root}__icon {
                path {
                    fill: $header-user-nav_icon-color-hover;
                }
            }

            #{$root}__label {
                color: $header-user-nav_label-color-hover;
            }
        }

        &--search {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
