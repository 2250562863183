@import 'config/variables';

$header_background: $color_shadow;

@import '../../../../theme-creativeshop/src/components/addtocart/addtocart.scss';

.#{$ns}addtocart {
    &__button {
        background-color: $header_background;
    }
}

