@import 'config/variables';

@import '../../../../theme-creativeshop/src/components/product-tile/product-tile.scss';

.#{$ns}product-tile {
    $root: &;

    &__badge {
        &--discount {
            background-color: $color_zeus;
        }
        &--new {
            background-color: $color_wafer;
            color: $color_zeus;
        }
    }

    &__stock {
        display: none;
    }
}
