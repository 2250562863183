/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 *
 * @see https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L130
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-header-user-nav {
  list-style: none;
  margin: 0;
  padding: 0 1rem;
  height: 100%; }
  @media (max-width: 23.115em) {
    .cs-header-user-nav {
      padding: 0 0.7rem; } }
  .cs-header-user-nav__item {
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    flex: 1 1 25%; }
    @media (min-width: 64em) {
      .cs-header-user-nav__item {
        flex: 1 1 33.33333%; } }
    @media (min-width: 64em) {
      .cs-header-user-nav__item--search {
        display: none; } }
  .cs-header-user-nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 1rem; }
    .cs-header-user-nav__link:hover {
      text-decoration: none; }
      .cs-header-user-nav__link:hover .cs-header-user-nav__icon path {
        fill: #31261D; }
      .cs-header-user-nav__link:hover .cs-header-user-nav__label {
        color: #696969; }
    .cs-header-user-nav__link--search:hover {
      cursor: pointer; }
  .cs-header-user-nav__icon-wrapper {
    position: relative; }
  .cs-header-user-nav__icon {
    width: 2.6rem;
    height: 2.6rem;
    display: block;
    margin: 0 auto; }
    .cs-header-user-nav__icon path {
      fill: #31261D; }
  .cs-header-user-nav__label {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 0.3rem;
    color: #696969;
    text-decoration: none;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    /* 1 */ }
    @media (min-width: 48em) {
      .cs-header-user-nav__label {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: auto;
        padding: 0;
        position: static;
        width: auto;
        white-space: normal; } }
  .cs-header-user-nav__qty-counter {
    display: inline-flex;
    background-color: #31261D;
    color: #fff;
    transform: skew(-15deg);
    border: 0;
    border-radius: 0.2rem;
    padding: 0 0.6rem;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 100%;
    font-weight: 700;
    height: 1.8em;
    background-clip: padding-box;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    opacity: 0;
    transition: opacity 0.3s; }
    @media (min-width: 48em) {
      .cs-header-user-nav__qty-counter {
        right: -1rem; } }
    .cs-header-user-nav__qty-counter--wishlist {
      background-color: #31261D;
      color: #000; }
    .cs-header-user-nav__qty-counter--minicart {
      background-color: #e41f12;
      color: #fff; }
    .cs-header-user-nav__qty-counter--have-items {
      opacity: 1; }
  .cs-header-user-nav__qty-counter-span {
    transform: skew(15deg);
    min-width: 1ch; }

.cs-header-user-nav__qty-counter--minicart {
  background-color: #31261D; }

.cs-header-user-nav__icon {
  width: 2.6rem;
  height: 2.6rem;
  display: block;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .cs-header-user-nav__icon {
      width: 1.8rem;
      height: 1.8rem; } }
  .cs-header-user-nav__icon path {
    fill: #31261D; }

.cs-header-user-nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0.8rem; }
  .cs-header-user-nav__link:hover {
    text-decoration: none; }
    .cs-header-user-nav__link:hover .cs-header-user-nav__icon path {
      fill: #31261D; }
    .cs-header-user-nav__link:hover .cs-header-user-nav__label {
      color: #696969; }
  .cs-header-user-nav__link--search:hover {
    cursor: pointer; }
