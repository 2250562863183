@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'components/toolbar/mixins.scss';

@import '../../../../theme-creativeshop/src/components/toolbar/viewmode-switcher.scss';

.#{$ns}viewmode-switcher {
    $root: &;

    @include media('<tablet') {
        display: none;
    }

}
