/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Mixins for flexible containers declaration.
 */
/**
 * Defines page container. This mixin makes sure that the content has always equal padding
 * for mobile screens on both size of the device.
 */
/**
 * Negates parent container's padding and sets it own.
 * Somethimes this has to be done to make background even to other containers' content.
 */
/**
 * Defines container wrapper that makes sure contnet doesn't extend above certain width and is always centered.
 */
.cs-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box; }
  .cs-container__inner, .cs-container__reviews {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    position: relative; }
    .cs-container__inner:before, .cs-container__reviews:before, .cs-container__inner:after, .cs-container__reviews:after {
      content: '';
      display: table;
      clear: both; }
    .cs-container__inner--flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      @media (min-width: 48em) {
        .cs-container__inner--flex {
          flex-wrap: nowrap; } }
      .cs-container__inner--flex:after {
        display: none; }
  .cs-container__aside {
    width: 100%; }
    @media (min-width: 48em) {
      .cs-container__aside {
        width: 40%; } }
    @media (min-width: 64em) {
      .cs-container__aside {
        width: 32%; } }
    @media (min-width: 90em) {
      .cs-container__aside {
        width: 30%; } }
  .cs-container__main-column {
    width: 56%;
    position: relative; }
    @media (min-width: 64em) {
      .cs-container__main-column {
        width: 65%; } }
    @media (min-width: 90em) {
      .cs-container__main-column {
        width: 67%; } }
    @media (max-width: 47.99em) {
      .cs-container__main-column {
        max-width: 100% !important;
        width: 100% !important; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .page-product-bundle .cs-container__main-column {
    width: 100%;
    margin-right: 0; } }
    @media (min-width: 64em) {
      .cs-container__main-column--space-top {
        padding-top: 3rem; } }
  .cs-container__reviews {
    padding-bottom: 2rem; }
    @media (min-width: 48em) {
      .cs-container__reviews {
        background: #fff;
        margin-top: 3rem;
        padding: 0 2rem; } }
    @media (min-width: 90em) {
      .cs-container__reviews {
        padding: 2rem 0 0; } }
    .cs-container__reviews:after {
      content: '';
      display: table;
      clear: both; }
  .cs-container__cart-coupons {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 64em) {
      .cs-container__cart-coupons {
        flex-wrap: nowrap;
        justify-content: space-between; } }
  .cs-container--contrast {
    background-color: #f0f0f0; }
    @media (min-width: 64em) {
      .cs-container--contrast {
        background-image: url("../images/background.png");
        background-size: cover;
        background-repeat: no-repeat; } }
  .cs-container--top-bar {
    background-color: #31261D;
    max-width: none; }
  .cs-container--spacious {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--spacious {
        padding-top: 3rem;
        padding-bottom: 3rem; } }
    @media (min-width: 48em) {
      .catalog-category-view .cs-container--spacious,
      .catalogsearch-result-index .cs-container--spacious {
        padding-top: 0; } }
  .cs-container--image-teaser-window-width, .cs-container--store-locator {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--grid-teaser {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; }
  .cs-container--hero-teaser {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0; }
  .cs-container--noscroll {
    overflow: hidden; }
  @media (min-width: 64em) {
    .cs-container--pdp {
      padding-top: 0; } }
  .cs-container--usps .cs-container--headline {
    padding: 0; }
  .cs-container--create-account-page, .cs-container--login-page, .cs-container--success-page, .cs-container--logout-page {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    @media (min-width: 64em) {
      .cs-container--create-account-page, .cs-container--login-page, .cs-container--success-page, .cs-container--logout-page {
        padding-top: 3rem;
        padding-bottom: 5rem; } }

@media (min-width: 48em) and (max-width: 63.99em) {
  .cs-container--my-account-page {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }
  .cs-container--ontop {
    z-index: 301; }
  .cs-container--viewport-width {
    max-width: none; }
  @media (max-width: 47.99em) {
    .cs-container--page-pdp-details-aw_pq_ajax_container {
      padding: 0; } }
  .cs-container--cart-page .cs-container--products-carousel {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--cart-page .cs-container--headline {
    padding-left: 0;
    padding-right: 0; }
  .cs-container--cms-teaser {
    margin-top: 2rem; }
  @supports (display: grid) and (grid-auto-flow: row) {
    .cs-container--grid-teaser {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      overflow: visible; } }
  .cs-container--footer-usps .cs-image-teaser__content {
    margin: 0 !important; }

.cs-container--top-bar {
  background-color: #31261D; }
