@import 'config/variables';
@import 'config/breakpoints';
@import 'vendors/include-media';
@import 'components/toolbar/mixins.scss';

@import '../../../../theme-creativeshop/src/components/toolbar/limiter.scss';

.#{$ns}limiter {
    $root: &;

    @include media('<tablet') {
        margin: 0 1rem 0 0.7rem;
    }

}
