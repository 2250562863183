@import 'config/variables';
@import 'vendors/include-media';
@import 'pages/layout/2columns-left/mixin';

@import '../../../../theme-creativeshop/src/pages/category/category.scss';

.#{$ns}page-category {

    &__toolbar-wrapper {
        &--top {

            @include media('<tablet') {
                border-bottom: 0.5rem 0 0;
            }

            .#{$ns}toolbar__item {
                &--limiter,
                &--pagination {
                    display: block;
                }
            }
        }
    }

    &__description {
        margin-top: 10rem;
        color: $color_zeus;
        a {
            color: $color_shadow;
        }
        strong {
            color: $color_shadow;
        }
        p {
            text-align: justify;
        }
    }

    h1 {
        font-size: 2.5rem;
        text-transform: capitalize;
        color: $color_zeus;
    }

    @include media('<tablet') {
        padding-top: 0rem;
    }

}

.category-image {
    margin: 2rem 0;
    @include media('<tablet') {
        margin: 0;
    }
    /*full width category image*/
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
        flex-shrink: 0;
        min-width: 100%;
        max-height: 300px;
    }
}
