@import 'config/variables';

$topbar_item-text-color: $color_white;
$topbar_item-hours-color: $color_white;
$topbar_link-color: $color_white;
$topbar_link-color-action: $color_white;

@import '../../../../theme-creativeshop/src/components/topbar/topbar.scss';

.#{$ns}topbar {

    &__item {
        color: $topbar_item-text-color;
    }

    &__text {
        color: $topbar_item-text-color;
    }

    &__link {
        @include link(
            $color: $topbar_link-color,
            $color-hover: $topbar_link-color-action,
        )
    }

    &__phone-number {
        color: $topbar_item-hours-color;

        &:hover {
            color: $topbar_item-hours-color;
        }
    }

}

