@import 'config/variables';

$footer_text-color: $color_wafer;
$footer_background: $color_zeus;
$footer_logo-width: 13.5em;
$footer_logo-height: 9.5em;
$footer_custom_font_size: 1.2rem !default;
$footer_promo-display-on-mobile: true;

@import '../../../../theme-creativeshop/src/components/footer/footer.scss';

.#{$ns}footer {
    font-size: $footer_custom_font_size;
    background: $footer_background;

    &__item {

        &--promo {
            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 1;
                padding-right: 2rem;
            }

            @include media('<laptop') {
                text-align: center;
            }
        }

        &--links {
            @include media('>=laptop') {
                flex-basis: auto;
                flex-grow: 5;
                padding-right: 2rem;
            }
        }

        &--extras {
            img {
                @include media('>=laptop') {
                    margin-top: 5rem;
                }
            }
        }
    }

    &__section {
        @include media('>=tablet') {
            padding-right: 3.8rem;
        }

        &--newsletter {
            @include media('>=tablet') {
                padding-right: 2rem;
            }
        }
    }

    &__section-title,
    [data-role='title']{
        color: $color_cold-turkey;
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__shop-logo {
        margin-bottom: 5rem;

        @include media('<laptop') {
            margin: auto;
            margin-bottom: 3rem;
            margin-top: 2rem;
        }
    }
}
