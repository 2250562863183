@import 'config/variables';
@import 'vendors/include-media';
@import 'utils/reset-list-style';
@import 'components/pseudoicon/mixin';
@import 'components/button/mixin';
@import 'components/field/mixin';

@import '../../../../theme-creativeshop/src/components/aftersearch-nav/aftersearch-nav.scss';

.#{$ns}aftersearch-nav {
    $root: &;
    
    @include media('<tablet') {
        margin: 0 -#{$aftersearch-nav--mobile_bleed} 0.5rem;
    }

}
