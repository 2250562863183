/**
 * Side categories tree navigation
 */
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
.cs-side-nav__parent {
  margin-bottom: 1.5rem;
  padding: 1.5rem 0;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid #dcdcdc; }
  .cs-side-nav__parent-link, .cs-side-nav__parent-link:visited {
    color: #31261D;
    transition: color 0.15s linear; }
    .cs-side-nav__parent-link:hover, .cs-side-nav__parent-link:visited:hover {
      color: #31261D;
      text-decoration: none; }

.cs-side-nav__category-name, .cs-side-nav__parent-category-name {
  hyphens: auto; }

.cs-side-nav__products-counter {
  font-weight: 400;
  color: #888; }
  .cs-side-nav__products-counter:before {
    content: '('; }
  .cs-side-nav__products-counter:after {
    content: ')'; }

.cs-side-nav__list {
  margin: 0 0 1rem;
  padding: 0;
  list-style: none; }
  .cs-side-nav__list--subcats {
    margin: 1rem 0 2rem;
    padding-left: 2rem; }
    .cs-side-nav__list--subcats .cs-side-nav__link {
      color: #888; }
      .cs-side-nav__list--subcats .cs-side-nav__link:visited {
        color: #888; }
      .cs-side-nav__list--subcats .cs-side-nav__link--current {
        font-weight: 700;
        color: #888; }
        .cs-side-nav__list--subcats .cs-side-nav__link--current:hover {
          color: #888; }

.cs-side-nav__item {
  font-weight: 400;
  text-transform: none;
  font-size: 1.4rem; }

.cs-side-nav__link,
.cs-side-nav__link a {
  color: #31261D;
  transition: color 0.15s linear; }
  .cs-side-nav__link:hover,
  .cs-side-nav__link a:hover {
    color: #31261D;
    text-decoration: none; }
  .cs-side-nav__link:visited,
  .cs-side-nav__link a:visited {
    color: #31261D; }
    .cs-side-nav__link:visited:hover,
    .cs-side-nav__link a:visited:hover {
      color: #31261D; }
  .cs-side-nav__link--current,
  .cs-side-nav__link a--current {
    font-weight: 700;
    color: #31261D; }
    .cs-side-nav__link--current:hover, .cs-side-nav__link--current:visited,
    .cs-side-nav__link a--current:hover,
    .cs-side-nav__link a--current:visited {
      color: #31261D; }
