/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-headline {
  font-size: 1rem;
  clear: both;
  padding: 2em 0;
  margin: 2em 0;
  margin: 0;
  padding: 0;
  margin: 0 0 3rem; }
  @media (min-width: 48em) {
    .cs-headline {
      margin: 0 0 4rem; } }
  .cs-headline--skip-spacing {
    margin: 0; }
  .cs-headline__title {
    display: block;
    text-align: center;
    align-items: center;
    margin: 0;
    font-size: 2.6em;
    font-weight: 700;
    color: #31261D;
    text-transform: uppercase; }
    @media (min-width: 48em) {
      .cs-headline__title {
        display: flex; } }
    .cs-headline__title:before, .cs-headline__title:after {
      content: '';
      display: none;
      height: 1px;
      flex-grow: 1;
      background-color: #dcdcdc; }
      @media (min-width: 48em) {
        .cs-headline__title:before, .cs-headline__title:after {
          display: flex; } }
    .cs-headline__title:before {
      margin-right: 0.6em; }
    .cs-headline__title:after {
      margin-left: 0.6em; }
  .cs-headline__subtitle {
    color: #888;
    font-size: 1.3em;
    font-weight: 400;
    text-transform: none;
    margin: 0;
    display: none;
    text-align: center; }
    @media (min-width: 48em) {
      .cs-headline__subtitle {
        display: block; } }
