@charset "UTF-8";
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/***
 * Remove browser's margins, paddings and list style rules
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
.cs-breadcrumbs {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  font-weight: 400; }
  .cs-breadcrumbs__list {
    margin: 0;
    padding: 0;
    list-style: none; }
  .cs-breadcrumbs__item {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0 1.95em 0 0;
    cursor: pointer;
    font-size: 1.2rem; }
    .cs-breadcrumbs__item:last-child {
      padding: 0;
      color: #31261D;
      cursor: default; }
    .cs-breadcrumbs__item:not(:last-child):after {
      position: absolute;
      top: 50%;
      right: 0.8em;
      content: "⁄";
      transform: translateY(-50%);
      line-height: 1;
      color: #888; }
  .cs-breadcrumbs__link {
    position: relative;
    display: inline-block;
    color: #888; }
    .cs-breadcrumbs__link:visited {
      color: #888; }
    .cs-breadcrumbs__link:hover {
      text-decoration: none;
      color: #31261D; }
  .cs-breadcrumbs__separator-icon {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    width: 0.8em;
    height: 0.8em;
    fill: #888; }

.cs-container--breadcrumbs {
  display: block;
  background: #f0f0f0; }
  @media (min-width: 48em) {
    .cs-container--breadcrumbs {
      display: block;
      background: #f0f0f0 url("../images/breadcrumbs.png") no-repeat right/auto 6.8rem; } }
