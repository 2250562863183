@import 'config/variables';

$container_background-top-bar: $color_zeus;

@import '../../../../theme-creativeshop/src/components/container/container.scss';

.#{$ns}container {
    &--top-bar {
        background-color: $container_background-top-bar;
    }
}

