/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/**
 * Breakpoint component used by include-media vendor library.
 */
/**
 * Extracts nested keys from given map.
 * Example: deep-get($some-map, some, nested, keys);
 */
@use "sass:map";
/**
* Default project breakpoints - [em]
*/
/* 
 * Function to get value from a list of values, based on side
 * @param {string} $variable: variable with a list of values (like paddings, margins etc.);
 * @param {string} $side: side (up / right / down / left) for which we want to get value
 * Example:
 * $component_padding: 1rem 2rem 3rem;
 * get-value-from-list($component_padding, 'right') // 2rem
 * get-value-from-list($component_padding, 'bottom') // 3rem
 */
/* stylelint-disable block-no-empty */
/* stylelint-enable */
.cs-aftersearch-nav-state {
  font-size: 1.4rem;
  padding: 0.8em 0 0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  overflow: hidden; }
  .cs-aftersearch-nav-state__items {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    margin-right: -0.8em; }
    .cs-aftersearch-nav-state__items:after {
      content: '';
      flex: 1;
      order: 1; }
  .cs-aftersearch-nav-state__item {
    display: flex;
    align-items: flex-start;
    margin: 0 0.8em 0.8em 0;
    order: 1; }
    .cs-aftersearch-nav-state__item--clear {
      display: none;
      order: 2;
      width: 100%; }
      .cs-aftersearch-nav-state__item--clear:nth-child(n + 3) {
        display: block; }
  .cs-aftersearch-nav-state__link {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    line-height: 1.275em;
    margin: 0;
    text-transform: none;
    padding: 0.75em 1em;
    box-sizing: border-box;
    transition: background-color 0.5s, border-color 0.5s, fill 0.5s, color 0.5s;
    vertical-align: middle;
    z-index: 5;
    will-change: transform, background-color, border-color, fill, color;
    font-size: 0.8em; }
    .cs-aftersearch-nav-state__link::before, .cs-aftersearch-nav-state__link::after {
      content: none; }
    .cs-aftersearch-nav-state__link::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0; }
    .cs-aftersearch-nav-state__link, .cs-aftersearch-nav-state__link:visited {
      cursor: pointer;
      border-radius: 3px;
      outline-offset: 1px;
      text-decoration: none;
      box-shadow: none;
      border: 1px solid #31261D;
      color: #31261D;
      background-color: #fff; }
      .cs-aftersearch-nav-state__link *, .cs-aftersearch-nav-state__link:visited * {
        color: #31261D;
        fill: #31261D; }
    .cs-aftersearch-nav-state__link:hover:not([disabled]), .cs-aftersearch-nav-state__link:focus:not([disabled]), .cs-aftersearch-nav-state__link:active:not([disabled]) {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      border: 1px solid #31261D;
      color: #fff;
      background-color: #31261D; }
      .cs-aftersearch-nav-state__link:hover:not([disabled]) *, .cs-aftersearch-nav-state__link:focus:not([disabled]) *, .cs-aftersearch-nav-state__link:active:not([disabled]) * {
        color: #fff;
        fill: #fff; }
    .cs-aftersearch-nav-state__link[disabled] {
      opacity: 0.65;
      cursor: auto; }
  .cs-aftersearch-nav-state__text {
    margin-top: 0;
    z-index: 1;
    font-weight: 700;
    vertical-align: baseline;
    display: inline-block;
    line-height: 1.275em;
    letter-spacing: normal;
    padding-right: 0;
    padding-left: 4em;
    padding-left: 1.8em; }
  .cs-aftersearch-nav-state__label:after {
    content: ': '; }
  .cs-aftersearch-nav-state__value {
    font-weight: normal; }
  .cs-aftersearch-nav-state__remove-icon {
    max-width: 1em;
    max-height: 1.4rem;
    z-index: 2;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    margin-left: 0;
    left: 1em;
    height: 0.8em;
    width: 0.8em; }
    .cs-aftersearch-nav-state__remove-icon path {
      fill: currentColor; }
