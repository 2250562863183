@import 'config/variables';
@import 'utils/reset-list-style';
@import 'components/typography/mixin';

$search-results-cms_item-gutter: $page_edge-gutter !default;
$search-results-cms_item-font-size: 1.5rem !default;
$search-results-cms_item-line-height: 1.2 !default;
$search-results-cms_icon-fill: $color_text-400 !default;
$search-results-cms_title-color: $color_primary-500 !default;
$search-results-cms_title-gutter: $page_edge-gutter !default;

$search-results-cms_related-terms_title_headline-level: $font-size_headline-3 !default;

.#{$ns}search-results-cms {
    $root: &;
    @include reset-list-style();

    &__item {
        margin-bottom: 0 !important;

        & + & {
            margin-top: $search-results-cms_item-gutter;
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        font-size: $search-results-cms_item-font-size;
        line-height: $search-results-cms_item-line-height;
    }

    &__icon {
        fill: $search-results-cms_icon-fill;
        width: 2em;
        height: 2em;
        flex-shrink: 0;
    }

    &__title {
        color: $search-results-cms_title-color;
        margin-left: $search-results-cms_title-gutter;
    }
}

.catalogsearch-result-index {
    dt.title {
        @include headline(
            $level: $search-results-cms_related-terms_title_headline-level
        );
    }
}
