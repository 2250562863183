@import 'config/variables';

$header_background: $color_wafer;
$header_border-bottom: 0;

@import '../../../../theme-creativeshop/src/components/header/header.scss';

.#{$ns}container {
    &--header {
        background-color: $header_background;
    }
}

.#{$ns}header {
    @include media('<laptop') {
        &__content {
            z-index: 500;
        }
    }

    &__logo {
        @include media('>=tablet') {
            padding: 0.7rem 0 0.7rem 0.7rem;
        }
    }
}
.hide {
    .cs-offcanvas-navigation__item--all-products {
        display: none;
    }
}
