@import '../../../../theme-creativeshop/src/components/navigation/navigation.scss';

li[data-category-id = '385'] {
    > a {
        pointer-events: none;
    }
    div.cs-navigation__flyout {
        ul.cs-navigation__list {
            li.cs-navigation__item[data-category-id = '386'] {
                > a {
                    pointer-events: none;
                }
            }
            li.cs-navigation__item[data-category-id = '387'] {
                > a {
                    pointer-events: none;
                }
            }
        }
    }
}

li[data-category-id = '414'] {
    > a {
        pointer-events: none;
    }
    div.cs-navigation__flyout {
        ul.cs-navigation__list {
            li.cs-navigation__item[data-category-id = '415'] {
                > a {
                    pointer-events: none;
                }
            }
        }
    }
}

ul[data-parent-item-id = '79'] {
    column-count: 2;
    column-gap: 5px;
}

@media screen and (min-width:1023px ) {
    .cs-navigation__list--level_2 {
        position: relative;
    }
    .cs-navigation__list--level_3 {
        display: none;
        position: absolute;
        background: #f5f5f5;
        width: 100%;
        height: auto;
        z-index: 9;
        left: 185px;
        padding: 10px 15px;
        top: 0;
    }
    .cs-navigation__list--level_3.show {
        display: block;
    }
    li.cs-navigation__item.cs-navigation__item--level_2 {
        position: relative;
    }
    li.cs-navigation__item.cs-navigation__item--level_2[data-category-id="278"] {
        position: initial;
    }
    li.cs-navigation__item.cs-navigation__item--level_2[data-category-id="279"] {
        position: initial;
    }
    li.cs-navigation__item.cs-navigation__item--level_2[data-category-id="281"] {
        position: initial;
    }
    li.cs-navigation__item.cs-navigation__item--level_2[data-category-id="282"] {
        position: initial;
    }
    li.cs-navigation__item.cs-navigation__item--level_2[data-category-id="283"] {
        position: initial;
    }
}